//
//
//
//
//
//

export default {
  data: () => ({
    open: false,
    userNavigation: [
      {
        name: 'Your profile',
        route: 'auth-profiel',
      },
      {
        name: 'Sign out',
        logout: true,
      },
    ],
  }),
  computed: {
    mainMenu() {
      return this.$store.state.menuMain;
    },
    availableLocales() {
      return this.$i18n.locales;
    },
  },
};
