import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _18aa87e3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5d1f4689 = () => interopDefault(import('../pages/auth/inloggen.vue' /* webpackChunkName: "pages/auth/inloggen" */))
const _d6ffb322 = () => interopDefault(import('../pages/auth/profiel.vue' /* webpackChunkName: "pages/auth/profiel" */))
const _4e7093ea = () => interopDefault(import('../pages/auth/registreren.vue' /* webpackChunkName: "pages/auth/registreren" */))
const _11956f61 = () => interopDefault(import('../pages/auth/verifieer.vue' /* webpackChunkName: "pages/auth/verifieer" */))
const _23698d40 = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/index" */))
const _60e09b08 = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/_token.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/_token" */))
const _34267570 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _18aa87e3,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/auth/inloggen",
    component: _5d1f4689,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___nl"
  }, {
    path: "/auth/profiel",
    component: _d6ffb322,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profiel___nl"
  }, {
    path: "/auth/registreren",
    component: _4e7093ea,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___nl"
  }, {
    path: "/auth/verifieer",
    component: _11956f61,
    pathToRegexpOptions: {"strict":true},
    name: "auth-verifieer___nl"
  }, {
    path: "/auth/wachtwoord-vergeten",
    component: _23698d40,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___nl"
  }, {
    path: "/en/auth/inloggen",
    component: _5d1f4689,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___en"
  }, {
    path: "/en/auth/profiel",
    component: _d6ffb322,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profiel___en"
  }, {
    path: "/en/auth/registreren",
    component: _4e7093ea,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___en"
  }, {
    path: "/en/auth/verifieer",
    component: _11956f61,
    pathToRegexpOptions: {"strict":true},
    name: "auth-verifieer___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten",
    component: _23698d40,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten/:token?",
    component: _60e09b08,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___en"
  }, {
    path: "/auth/wachtwoord-vergeten/:token?",
    component: _60e09b08,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___nl"
  }, {
    path: "/en/*",
    component: _34267570,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/",
    component: _18aa87e3,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/*",
    component: _34267570,
    pathToRegexpOptions: {"strict":true},
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
