import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_6475ee76 from 'nuxt_plugin_plugin_6475ee76' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_c736afc2 from 'nuxt_plugin_sentryserver_c736afc2' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_36ab3697 from 'nuxt_plugin_sentryclient_36ab3697' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_02ef19ed from 'nuxt_plugin_plugin_02ef19ed' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_745005fa from 'nuxt_plugin_cookieuniversalnuxt_745005fa' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_toast_341a7fec from 'nuxt_plugin_toast_341a7fec' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_workbox_34c37db7 from 'nuxt_plugin_workbox_34c37db7' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_2b89c46c from 'nuxt_plugin_metaplugin_2b89c46c' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_223a7984 from 'nuxt_plugin_iconplugin_223a7984' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_pluginutils_02988780 from 'nuxt_plugin_pluginutils_02988780' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_52938cea from 'nuxt_plugin_pluginrouting_52938cea' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_1f9115c4 from 'nuxt_plugin_pluginmain_1f9115c4' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_gtm_8a23561e from 'nuxt_plugin_gtm_8a23561e' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_libplugin491a3bed_a6828b08 from 'nuxt_plugin_libplugin491a3bed_a6828b08' // Source: ./lib.plugin.491a3bed.js (mode: 'all')
import nuxt_plugin_axios_19c2735b from 'nuxt_plugin_axios_19c2735b' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_nuxtapollomodule_f6a74a86 from 'nuxt_plugin_nuxtapollomodule_f6a74a86' // Source: ./nuxt-apollo-module.js (mode: 'all')
import nuxt_plugin_datefns_9f0776de from 'nuxt_plugin_datefns_9f0776de' // Source: ./date-fns.js (mode: 'all')
import nuxt_plugin_axios_630a4306 from 'nuxt_plugin_axios_630a4306' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_bodyscrolllock_0ed59419 from 'nuxt_plugin_bodyscrolllock_0ed59419' // Source: ../plugins/body-scroll-lock (mode: 'client')
import nuxt_plugin_directives_f60b4746 from 'nuxt_plugin_directives_f60b4746' // Source: ../plugins/directives (mode: 'all')
import nuxt_plugin_formulate_582614de from 'nuxt_plugin_formulate_582614de' // Source: ../plugins/formulate (mode: 'all')
import nuxt_plugin_user_9f0e3722 from 'nuxt_plugin_user_9f0e3722' // Source: ../plugins/mixins/user (mode: 'all')
import nuxt_plugin_validation_b46593c6 from 'nuxt_plugin_validation_b46593c6' // Source: ../plugins/mixins/validation (mode: 'all')
import nuxt_plugin_smoothscroll_5fd40a14 from 'nuxt_plugin_smoothscroll_5fd40a14' // Source: ../plugins/smoothscroll (mode: 'client')
import nuxt_plugin_lazysizes_4201e7b6 from 'nuxt_plugin_lazysizes_4201e7b6' // Source: ../plugins/lazysizes (mode: 'client')
import nuxt_plugin_vcalendar_16d9fd0e from 'nuxt_plugin_vcalendar_16d9fd0e' // Source: ../plugins/v-calendar (mode: 'client')
import nuxt_plugin_auth_67a7de75 from 'nuxt_plugin_auth_67a7de75' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_meta_03be8c3f from 'nuxt_plugin_meta_03be8c3f' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Rappr","titleTemplate":(t) => {
      return t + (t === 'Rappr' ? '' : ' – Rappr');
    },"htmlAttrs":{"lang":"nl"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","hid":"description","content":""},{"name":"apple-mobile-web-app-title","hid":"apple-mobile-web-app-title","content":"Rappr"},{"name":"og:title","hid":"og:title","content":"Rappr"},{"name":"og:description","hid":"og:description","content":""},{"name":"og:site_name","hid":"og:site_name","content":"Rappr"}],"link":[{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Open+Sans&display=swap"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_6475ee76 === 'function') {
    await nuxt_plugin_plugin_6475ee76(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_c736afc2 === 'function') {
    await nuxt_plugin_sentryserver_c736afc2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_36ab3697 === 'function') {
    await nuxt_plugin_sentryclient_36ab3697(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_02ef19ed === 'function') {
    await nuxt_plugin_plugin_02ef19ed(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_745005fa === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_745005fa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_341a7fec === 'function') {
    await nuxt_plugin_toast_341a7fec(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_34c37db7 === 'function') {
    await nuxt_plugin_workbox_34c37db7(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_2b89c46c === 'function') {
    await nuxt_plugin_metaplugin_2b89c46c(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_223a7984 === 'function') {
    await nuxt_plugin_iconplugin_223a7984(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_02988780 === 'function') {
    await nuxt_plugin_pluginutils_02988780(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_52938cea === 'function') {
    await nuxt_plugin_pluginrouting_52938cea(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_1f9115c4 === 'function') {
    await nuxt_plugin_pluginmain_1f9115c4(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_8a23561e === 'function') {
    await nuxt_plugin_gtm_8a23561e(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin491a3bed_a6828b08 === 'function') {
    await nuxt_plugin_libplugin491a3bed_a6828b08(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_19c2735b === 'function') {
    await nuxt_plugin_axios_19c2735b(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtapollomodule_f6a74a86 === 'function') {
    await nuxt_plugin_nuxtapollomodule_f6a74a86(app.context, inject)
  }

  if (typeof nuxt_plugin_datefns_9f0776de === 'function') {
    await nuxt_plugin_datefns_9f0776de(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_630a4306 === 'function') {
    await nuxt_plugin_axios_630a4306(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bodyscrolllock_0ed59419 === 'function') {
    await nuxt_plugin_bodyscrolllock_0ed59419(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_f60b4746 === 'function') {
    await nuxt_plugin_directives_f60b4746(app.context, inject)
  }

  if (typeof nuxt_plugin_formulate_582614de === 'function') {
    await nuxt_plugin_formulate_582614de(app.context, inject)
  }

  if (typeof nuxt_plugin_user_9f0e3722 === 'function') {
    await nuxt_plugin_user_9f0e3722(app.context, inject)
  }

  if (typeof nuxt_plugin_validation_b46593c6 === 'function') {
    await nuxt_plugin_validation_b46593c6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_smoothscroll_5fd40a14 === 'function') {
    await nuxt_plugin_smoothscroll_5fd40a14(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazysizes_4201e7b6 === 'function') {
    await nuxt_plugin_lazysizes_4201e7b6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vcalendar_16d9fd0e === 'function') {
    await nuxt_plugin_vcalendar_16d9fd0e(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_67a7de75 === 'function') {
    await nuxt_plugin_auth_67a7de75(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_03be8c3f === 'function') {
    await nuxt_plugin_meta_03be8c3f(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
