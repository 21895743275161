import Vue from 'vue';
import {mapState} from 'vuex';

const User = {
  install(Vue) {
    Vue.mixin({
      computed: mapState({
        user: state => (state.auth.user ? state.auth.user : {
          name: 'Tom Cook',
          email: 'tom@example.com',
          imageUrl:
            'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        }),
      }),
      // mounted() {
      //   if (this.user && this.$gtm) {
      //     this.$gtm.pushEvent({event: 'userId', userId: this.user.uuid});
      //   }
      // },
    });
  },
};

Vue.use(User);
