//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    date: null,
    modelConfig: {
      mask: 'DD-MM-YYYY',
    },
    attrs: [
      {
        key: 'today',
        highlight: {
          class: 'today',
        },
        dates: new Date(),
      },
    ],
  }),
  computed: {
    pickerAttributes() {
      return Object.assign(this.context.attributes, {id: null, class: null});
    },
    isDisabled() {
      return 'disabled' in this.context.attributes;
    },
  },
  watch: {
    date(newDate) {
      if (Object.prototype.toString.call(newDate) === '[object Date]') {
        this.context.model = this.$dateFns.format(newDate, 'yyyy-MM-dd');
      } else {
        this.context.model = null;
      }
    },
    'context.model'(newValue) {
      if (newValue) {
        this.date = new Date(newValue);
      }
    },
  },
  mounted() {
    if (this.context.model) {
      this.date = this.context.model;
    }

    this.context.model = this.date;
  },
  methods: {
    parseDate(value) {
      const regex = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
      if (regex.test(value)) {
        return value;
      }
      return false;
    },
  },
};
