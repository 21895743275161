export const DesktopFilter = () => import('../../components/base/DesktopFilter.vue' /* webpackChunkName: "components/desktop-filter" */).then(c => wrapFunctional(c.default || c))
export const DropDown = () => import('../../components/base/DropDown.vue' /* webpackChunkName: "components/drop-down" */).then(c => wrapFunctional(c.default || c))
export const SmallFilter = () => import('../../components/base/SmallFilter.vue' /* webpackChunkName: "components/small-filter" */).then(c => wrapFunctional(c.default || c))
export const User = () => import('../../components/user.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const DashboardDetails = () => import('../../components/dashboard/Details.vue' /* webpackChunkName: "components/dashboard-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardItem = () => import('../../components/dashboard/Item.vue' /* webpackChunkName: "components/dashboard-item" */).then(c => wrapFunctional(c.default || c))
export const DashboardStats = () => import('../../components/dashboard/Stats.vue' /* webpackChunkName: "components/dashboard-stats" */).then(c => wrapFunctional(c.default || c))
export const DashboardTable = () => import('../../components/dashboard/Table.vue' /* webpackChunkName: "components/dashboard-table" */).then(c => wrapFunctional(c.default || c))
export const FiltersFilter = () => import('../../components/filters/Filter.vue' /* webpackChunkName: "components/filters-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersSort = () => import('../../components/filters/Sort.vue' /* webpackChunkName: "components/filters-sort" */).then(c => wrapFunctional(c.default || c))
export const FormulateButton = () => import('../../components/formulate/Button.vue' /* webpackChunkName: "components/formulate-button" */).then(c => wrapFunctional(c.default || c))
export const FormulateCalendar = () => import('../../components/formulate/Calendar.vue' /* webpackChunkName: "components/formulate-calendar" */).then(c => wrapFunctional(c.default || c))
export const FormulateDate = () => import('../../components/formulate/Date.vue' /* webpackChunkName: "components/formulate-date" */).then(c => wrapFunctional(c.default || c))
export const FormulateToggle = () => import('../../components/formulate/Toggle.vue' /* webpackChunkName: "components/formulate-toggle" */).then(c => wrapFunctional(c.default || c))
export const ModalsEnableTwoFactorModal = () => import('../../components/modals/EnableTwoFactorModal.vue' /* webpackChunkName: "components/modals-enable-two-factor-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
